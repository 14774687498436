import { CompatibleProductTypeValues } from '@atlassian/mpac-types';
import { AtlassianProductHostingType } from '@atlassian/mpac-types/graphql-gateway-types';
export const MAX_VISIBLE_TABS_CONFIG = {
  xs: 2,
  sm: 4
};
export const AppListingTab = {
  Overview: 'overview',
  Reviews: 'reviews',
  Pricing: 'pricing',
  Support: 'support',
  Versions: 'versions',
  Installation: 'installation',
  PrivacyAndSecurity: 'privacy-and-security'
};
export const AppListingTabs = Object.keys(AppListingTab).map(key => AppListingTab[key]);
export const DEFAULT_TAB = AppListingTab.Overview;
export const CommunityTagForHostingType = {
  [AtlassianProductHostingType.Cloud]: 'cloud',
  [AtlassianProductHostingType.Server]: 'server',
  [AtlassianProductHostingType.DataCenter]: 'data-center'
};
export const HostingTypeForCompatibleProduct = {
  [CompatibleProductTypeValues.CLOUD_PRODUCT]: AtlassianProductHostingType.Cloud,
  [CompatibleProductTypeValues.SERVER_PRODUCT]: AtlassianProductHostingType.Server,
  [CompatibleProductTypeValues.DATACENTER_PRODUCT]: AtlassianProductHostingType.DataCenter
};
export const FREE_APP_CTA_LABEL_PRIMARY = 'Get it now';
export const FREE_APP_CTA_LABEL_SECONDARY = 'Free app';
export const PAID_APP_CTA_LABEL_TRY = 'Try it free';
export const PAID_APP_CTA_LABEL_START_TRIAL = 'Start free trial';
export const PAID_APP_CTA_LABEL_BUY = 'Buy now';
export const PAID_APP_CTA_LABEL_SUBSCRIBE = 'Subscribe Now';
export const LEARN_MORE_CTA_LABEL = 'Discover more';
export const PURCHASE_CTA_LABEL = 'Purchase';

/**
 * Test ids for important elements within app listing
 */
export const TEST_ID = {
  APP_LISTING_TAB: tabName => "app-listing__tab-".concat(tabName),
  // overview tab
  OVERVIEW_TAB_MORE_DETAILS_CONTENT: 'app-listing__more-details-content',
  OVERVIEW_TAB_YOUTUBE_IFRAME: 'app-listing__youtube-iframe',
  OVERVIEW_TAB_HERO_HIGHLIGHTS: 'hero-and-highlights-container',
  OVERVIEW_TAB_HERO_IMAGE: 'app-listing__hero-img',
  OVERVIEW_TAB_HIGHLIGHT_SUMMARY: 'app-listing__highlight-summary',
  OVERVIEW_TAB_HIGHLIGHT_IMAGE: 'app-listing__highlight-img',
  OVERVIEW_TAB_MEDIA_VIEWER: 'app-listing__media-viewer',
  OVERVIEW_TAB_RESOURCES: 'app-listing__overview__resources',
  OVERVIEW_TAB_PRIVACY_AND_SECURITY: 'app-listing__overview__privacy-security',
  OVERVIEW_TAB_INTEGRATION_DETAILS: 'app-listing__overview__integration-details',
  OVERVIEW_TAB_INTEGRATION_DETAILS_MODAL: 'app-listing__overview__integration-details--modal',
  OVERVIEW_TAB_PLANS_FEATURES_LIST: 'app-listing__overview__plans-features-list',
  PRIVACY_SECURITY: 'privacy-security',
  RESOURCE_LINKS: 'resource-links',
  INTEGRATION_DETAILS: 'integration-details',
  GALLERY: 'gallery',
  CERTIFICATION_SECTION: 'certification-section',
  CLOUD_FORTIFIED_INFO_MODAL: 'cloudfortified-info-modal',
  // overview tab -> Resources section -> cloud migration subsection
  CLOUD_MIGRATION_ALTERNATE_APP: 'cloud-migration__alternate-app',
  CLOUD_MIGRATION_MIGRATION_DOCUMENTATION: 'cloud-migration__migration-documentation',
  CLOUD_MIGRATION_FEATURE_DIFFERENCE: 'cloud-migration__feature-difference',
  // reviews tab
  REVIEWS_TAB_HEADING: 'reviews-tab__heading',
  REVIEWS_TAB_LOADING: 'reviews-tab__skeleton',
  REVIEWS_TAB_REVIEW_FORM_CHECKBOX: 'reviews-tab__review-form-checkbox',
  REVIEWS_TAB_REVIEW_FORM_TEXTAREA: 'reviews-tab__review-form-textarea',
  REVIEWS_TAB_USER_REVIEW: 'reviews-tab__user-review',
  REVIEWS_TAB_OWN_REVIEW: 'reviews-tab__own-review',
  REVIEWS_TAB_DELETE_REVIEW: 'reviews-tab__delete-review',
  REVIEWS_TAB_RESPOND_REVIEW: 'reviews-tab__respond-review',
  REVIEWS_TAB_FLAG_UNFLAG_REVIEW: 'reviews-tab__flag-unflag-review',
  REVIEWS_TAB_SIGN_IN_LINK: 'reviews-tab__sign-in-link',
  REVIEWS_TAB_WRITE_EDIT_REVIEW_BUTTON: 'reviews-tab__write-edit-review-button',
  REVIEWS_TAB_PUBLISH_REVIEW_BUTTON: 'reviews-tab__publish-review-button',
  REVIEWS_TAB_LOAD_MORE_LOADING: 'reviews-tab__load-more-skeleton',
  // support
  SUPPORT_TAB_SUPPORT_TAGLINE: 'app-listing__support-tagline',
  SUPPORT_TAB_EMAIL_ID: 'app-listing__support-link__email-id',
  SUPPORT_TAB_PHONE_NUMBER: 'app-listing__support-link__phone-number',
  SUPPORT_TAB_STATUS_PAGE_URL: 'app-listing__support-link__status-page-url',
  SUPPORT_TAB_SUPPORT_TIMINGS: 'app-listing__support-contact__availability-timings',
  // pricing
  PRICING_TAB_VIEW_ADDITIONAL_PRICING: 'app-listing__pricing-tab-view-additional-pricing',
  PRICING_TAB_INSTALL_CTA: 'app-listing__pricing-tab__install-cta',
  PRICING_TAB_EDITIONS_LOADING: 'pricing-tab-editions__skeleton',
  // installation
  INSTALLATION_TAB_STEP_SCREENSHOT: 'app-listing__installation-step-screenshot',
  // cta
  CTA_INSTALL_APP_BTN: 'app-listing__install-app-btn',
  // hosting selector
  HOSTING_SELECTOR_MODAL_REQUEST_APP: 'hosting-selector-modal__request-app-btn',
  HOSTING_SELECTOR_MODAL_COLUMN_CLOUD: 'hosting-selector-modal__column-cloud',
  HOSTING_SELECTOR_MODAL_COLUMN_SERVER: 'hosting-selector-modal__column-server',
  HOSTING_SELECTOR_MODAL_COLUMN_DATACENTER: 'hosting-selector-modal__column-datacenter',
  HOSTING_SELECTOR_MODAL_PRIMARY_COLUMN_CTA: 'hosting-selector-modal__primary-column-cta',
  /**
   * Hosting dropdown
   * This value is a prefix, the actual testId is created by the atlaskit dropdown component:-
   * - `${HOSTING_DROPDOWN}--trigger` (for the clickable trigger)
   * - `${HOSTING_DROPDOWN}--content` (for the options container)
   */
  HOSTING_DROPDOWN: 'app-listing__hosting-dropdown',
  // request app
  REQUEST_APP_BTN: 'app-listing__request-app-btn',
  REQUEST_APP_MODAL_CLOSE_BTN: 'request-app-modal__close-modal-btn',
  REQUEST_APP_MODAL_SUBMIT_BTN: 'request-app-modal__submit-request-btn',
  // gallery media viewer
  MEDIA_VIEWER_FILE_NAME: 'media-viewer-file-name',
  MEDIA_VIEWER_CLOSE_BTN: 'media-viewer-close-button',
  MEDIA_VIEWER_IMAGE: 'media-viewer-image',
  // versions tab
  VERSION_SECTION: 'versions-section',
  RESOURCE_SECTION: 'resources-section'
};
export const ELEMENT_ID = {
  APP_LISTING_TABS: 'app-listing__tabs',
  CLOUD_MIGRATION_SECTION_LINKS: 'app-listing__cloud-migration-links',
  REVIEW_GUIDELINE_BANNER_AND_FORM: 'app-listing__review-guideline-banner-and-form'
};

/** Minumum number of fractional digits to use when representing the price in PUP */
export const PER_USER_PRICING_FORMAT_MIN_FRACTION_DIGITS = 2;