import {
  type MarketplaceStoreEditionType,
  AtlassianProductHostingType,
} from '@atlassian/mpac-types/graphql-gateway-types';
import capitalize from 'lodash/capitalize';

const contentGroupHostingIndex = 2;
const contentGroupCompatibleProductIndex = 3;
const contentGroupPaymentModelIndex = 4;

let currentPluginId: string | null = null;

const pluginIdToGaIdMap: Record<string, any> = {};

function addPartnerGaId(gaId, pluginId) {
  if (pluginId in pluginIdToGaIdMap) {
    pluginIdToGaIdMap[pluginId].add(gaId);
  } else {
    pluginIdToGaIdMap[pluginId] = new Set([gaId]);
  }
  window[`ga-disable-${gaId}`] = false;
}

function disableGaId(gaId: string) {
  window[`ga-disable-${gaId}`] = true;
}

function resetPartnerGaIds(pluginId) {
  if (pluginId in pluginIdToGaIdMap) {
    pluginIdToGaIdMap[pluginId].forEach((gaId) => disableGaId(gaId));
    delete pluginIdToGaIdMap[pluginId];
  }
  currentPluginId = null;
}

type GaConfig = {
  anonymize_ip?: boolean;
  send_page_view?: boolean;
  custom_map?: {
    [key: string]: string;
  };
  page_title?: string;
  linker?: {
    accept_incoming: boolean;
  };
  groups?: string;
  client_id?: string;
  session_id?: string;
};

function getTagConfig(pluginKey: string, buildNum: string, title: string) {
  const config: GaConfig = {
    anonymize_ip: true,
    send_page_view: false,
    custom_map: {
      dimension1: pluginKey,
      dimension2: buildNum.toString(),
    },
    page_title: title,
    linker: {
      accept_incoming: true,
    },
    groups: 'default',
  };
  const params = new URLSearchParams(document.location.search);
  const clientId = params.get('clientId');
  const sessionId = params.get('sessionId');
  if (clientId) {
    config.client_id = clientId;
  }
  if (sessionId) {
    config.session_id = sessionId;
  }
  return config;
}

export function registerTrackerAndPluginVars(
  gaId: string,
  pluginId: string,
  pluginKey: string,
  buildNum: string,
  title = document.title
) {
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  if (typeof gtag === 'undefined') return;
  if (gaId) {
    currentPluginId = pluginId;
    // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
    gtag('set', 'dimension1', pluginKey);
    // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
    gtag('set', 'dimension2', buildNum.toString(10));
    const config = getTagConfig(pluginKey, buildNum, title);
    // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
    gtag('config', gaId, config);
    addPartnerGaId(gaId, pluginId);
    // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
    gtag('event', 'page_view', { title });
  } else {
    resetPartnerGaIds(pluginId);
    // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
    gtag('event', 'page_view', { send_to: 'atlassianGroup', title });
  }
}

// Marks an analytics event to be safe to forward to
// partner (developer of `pluginId`). All events executed in the
// callback will be also forwarded to the partner of the plugin ID/
// Note: For this to work `registerTrackerAndPluginVars` must have been
// initialized with the tracker name for the plugin
export function safeToForwardToPartner(pluginId, callback) {
  currentPluginId = pluginId;
  callback();
  currentPluginId = null;
}

// @ts-expect-error [MC-2850] - TS6133 - 'gaId' is declared but its value is never read.
function unregisterTrackerAndPluginVars(gaId, pluginId) {
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  if (typeof gtag === 'undefined') return;
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  gtag('set', 'dimension1', null);
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  gtag('set', 'dimension2', null);
  resetPartnerGaIds(pluginId);
}

export function trackPageView(url, skipAddlTrackers?) {
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  if (typeof gtag === 'undefined') return;

  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  gtag('event', 'page_view', { send_to: 'atlassianGroup', page_path: url });
  if (!skipAddlTrackers && currentPluginId && currentPluginId in pluginIdToGaIdMap) {
    pluginIdToGaIdMap[currentPluginId].forEach((gaId) => {
      // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
      gtag('event', 'page_view', {
        send_to: gaId,
        page_path: url,
      });
    });
  }
}

export function trackEvent(category, action, label, value?, nonInteraction?) {
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  if (typeof gtag === 'undefined') return;
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  gtag('event', action, {
    event_category: category,
    event_label: label,
    ...(typeof value !== 'undefined' && { value }),
    ...(typeof nonInteraction !== 'undefined' && { nonInteraction }),
    send_to: 'atlassianGroup',
  });
  if (currentPluginId && currentPluginId in pluginIdToGaIdMap) {
    pluginIdToGaIdMap[currentPluginId].forEach((gaId) => {
      // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
      gtag('event', action, {
        event_category: category,
        event_label: label,
        ...(typeof value !== 'undefined' && { value }),
        ...(typeof nonInteraction !== 'undefined' && { nonInteraction }),
        send_to: gaId,
      });
    });
  }
}

export function setContentGroup(index, name) {
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  if (typeof gtag === 'undefined') return;
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  gtag('set', 'content_group' + index, name);
}

// @ts-expect-error [MC-2850] - TS6133 - 'name' is declared but its value is never read.
function setCustomVar(index, name, value) {
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  if (typeof gtag === 'undefined') return;
  // @ts-expect-error [MC-2850] - TS2304 - Cannot find name 'gtag'.
  gtag('set', 'dimension' + index, value);
}

export function markHasLoggedIn() {
  setCustomVar(3, 'Has logged in', 'Yes');
}

export function trackBuyButton(hostingType: string) {
  if (hostingType === AtlassianProductHostingType.Cloud) {
    trackEvent('Navigation', 'click', hostingType + ' Buy button');
  } else {
    trackEvent('Convert', 'click', hostingType + ' Buy button');
  }
  trackPageView('/buy', true);
  trackPageView(document.location.pathname + '/buy');
}

export function trackTryButton(hostingType: string) {
  if (hostingType === AtlassianProductHostingType.Cloud) {
    trackEvent('Navigation', 'click', hostingType + ' Try button');
  } else {
    trackEvent('Convert', 'click', hostingType + ' Try button');
  }
  trackPageView('/try', true);
  trackPageView(document.location.pathname + '/try');
}

export function trackInstallClick(hostingType: string, selectedPlan?: MarketplaceStoreEditionType) {
  trackEvent(
    'Convert',
    'click',
    selectedPlan
      ? `${hostingType} ${capitalize(selectedPlan)} Install button click`
      : `${hostingType} Install button click`
  );
}

export function trackRequestClick(hostingType: string, selectedPlan?: MarketplaceStoreEditionType) {
  trackEvent(
    'Convert',
    'click',
    selectedPlan
      ? `${hostingType} ${capitalize(selectedPlan)} Request button click`
      : `${hostingType} Request button click`
  );
}

export function trackTryModal(hostingType: string) {
  trackEvent('Navigation', 'click', hostingType + ' Try modal');
}

export function trackBuyModal(hostingType: string) {
  trackEvent('Navigation', 'click', hostingType + ' Buy modal');
}

export function trackGetItNow() {
  trackEvent('Convert', 'click', 'Get it now');
}

export function trackPricingCalculatorGetItNow(selectedPlan?: MarketplaceStoreEditionType) {
  trackEvent(
    'Navigation',
    'click',
    selectedPlan
      ? `Cloud Pricing Calculator ${capitalize(selectedPlan)} start free trial`
      : 'Cloud Pricing Calculator get it now'
  );
}

export function trackGetItNowModal() {
  trackEvent('Navigation', 'click', 'Get it now modal');
}

export function trackVendorSupportLinkClick(name) {
  trackEvent('VendorSupport', 'click', name);
}

function trackNavClickEvent(url, name, skipPageView) {
  trackEvent('Navigation', 'click', name);
  if (!skipPageView) {
    trackPageView(url);
  }
}

export function trackCategoryLinkClick() {
  trackEvent('Search', 'click', 'Single app add-on category link');
}

export function trackSeeMoreReviews() {
  trackEvent('Navigation', 'click', 'See more reviews');
}

export function trackLightboxView(imageUrl, isHighlight, cdnBase) {
  const eventName = isHighlight ? 'Highlight image' : 'Screenshot';
  trackEvent('Navigation', 'click', eventName);
  // only track pageviews for in-site resources (ie, not youtube)
  const relativizeUrl = (url) => url.replace(window.location.origin, '').replace(cdnBase, '');

  const relativeUrl = relativizeUrl(imageUrl);

  if (relativeUrl.match(/^\/[^/].*$/)) {
    trackPageView(relativeUrl);
  }
}

export function trackWatchAction(verb: string) {
  trackEvent('Watch', 'click', verb + ' add-on');
}

export function contentGroupHosting(data) {
  setContentGroup(contentGroupHostingIndex, data);
}

export function contentGroupCompatibleProduct(data) {
  setContentGroup(contentGroupCompatibleProductIndex, data);
}

export function contentGroupPaymentModel(data) {
  setContentGroup(contentGroupPaymentModelIndex, data);
}

export function trackUserActionClicked(actionText) {
  trackEvent('HeaderUserActions', 'click', actionText);
}

export function trackHelpActionClicked(actionText) {
  trackEvent('HeaderHelpActions', 'click', actionText);
}

export const SOLUTIONS_CATEGORY = 'DiscoveryLanding';
export const DISCOVER_PERSPECTIVE_CATEGORY = 'DiscoveryExplore';

export function trackSolutionsTileMounted(solution, addonKey, hitRank) {
  trackEvent(SOLUTIONS_CATEGORY, 'viewTile/landingTile/' + solution, addonKey, hitRank);
}

export function trackSolutionsLargeCuratedTileMounted(solution, addonKey, hitRank) {
  trackEvent(SOLUTIONS_CATEGORY, 'viewTile/landingLargeCuratedTile/' + solution, addonKey, hitRank);
}

export function trackSolutionsSmallCuratedTileMounted(solution, addonKey, hitRank) {
  trackEvent(SOLUTIONS_CATEGORY, 'viewTile/landingSmallCuratedTile/' + solution, addonKey, hitRank);
}

export function trackSolutionsLoadMoreClick(solution) {
  trackEvent(SOLUTIONS_CATEGORY, 'viewMore', solution);
}

export function trackCategoryTileClicked(category) {
  trackEvent(DISCOVER_PERSPECTIVE_CATEGORY, 'clickTile/category', category);
}

export function trackLookingForDataCenter() {
  trackEvent('Navigation', 'click', 'Looking for Data Center');
}

export const addVendorGAId = registerTrackerAndPluginVars;
export const removeVendorGAId = unregisterTrackerAndPluginVars;
export const trackTabChange = trackNavClickEvent;
export const trackDownloadLink = trackPageView;
